.reportCard_img {
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* padding: 8px; */
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-color-red);
  padding: 12px;
}
.reportCard_img img {
  background-color: #fff;
  width: 100%;
  height: auto;
  object-position: center center;
}
.reportCard_balance p {
  margin-bottom: 4px;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 500;
}
.reportCard_balance h4 {
  color: var(--main-color-red);
}

.reportCard_main {
  width: 100%;
  height: 105px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
  gap: 20px;
}

@media (max-width: 578px) {
  .reportCard_img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .reportCard_img img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 480px) {
  .reportCard_main {
    height: 85px;
  }
}
@media (max-width: 420px) {
  .reportCard_img {
    margin-left: 6px;
  }
  .reportCard_main {
    gap: 10px;
  }
}
