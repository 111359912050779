.container {
    /* max-width: 70%; */
    margin: auto;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}

.coverImageContainer {
    position: relative;
    margin-bottom: 100px;
    height: 400px;
}

.coverImageContainer .profile_div {
    position: absolute;
}

.InputSection_div {
    min-height: calc(100vh - 549px);
}



.inp_width {
    width: 100%;
}

.inp_div {
    margin-top: 20px;
}

.savebtn {
    background-color: var(--main-color-yellow);
    color: black;
    padding: 10px 40px;
    margin-top: 50px;
    font-size: 16px;
}

.profile_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.profile_text {
    text-align: center;
    white-space: nowrap;
}

.coverImageContainer {
    width: 100% !important;
    border-radius: unset !important;
    object-position: center center;
    object-fit: cover;
}

/*  */
.imagesDiv {
    height: 100%;
    display: flex;
    justify-content: center;
}

.coverEditBtn {
    right: 20px !important;
    bottom: -10px !important;
}

.imagesDiv .profile_div {}

.userImagesContainer {
    height: 400px;
    margin-bottom: 60px;
    position: relative;

}

.coverImage {
    width: 100% !important;
    height: 100% !important;
    border-radius: unset !important;
    border-radius: 10px !important;

}

.coverImageTagClass {
    object-fit: cover !important;
    object-position: center !important;
    border-radius: inherit !important;
}

.userImagesContainer .profile_div {
    position: absolute;
    bottom: -0%;
    left: 50%;
    transform: translate(-50%, 50%);
}

/*  */

.publicDocuments_div {
    margin-top: 30px;
}

.privateDocuments_div {
    margin-top: 30px;


}

.public_text {
    font-weight: 800 !important;
}

.doc_outter_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 15px 20px;
}

.doc_div {
    display: flex;
    align-items: center;
}

.file_text {
    margin-left: 10px;
    margin-bottom: 0;
}

.greyText {
    color: gray;
}

.doc_para_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.para_text {
    margin: 0;
}

.c_p {
    cursor: pointer;
}

.choose_btn {
    color: var(--main-color-red);
    border: 1px solid var(--main-color-red);
    border-radius: 5px;
    font-size: 14px !important;
    padding: 10px 40px;
    margin-top: 20px;
    background-color: white;
}

/* For Certificates */
.certificatesRow {}

.certificatesBoxCol {
    background-color: var(--light-gray);
    border-radius: 10px;
    margin-top: 20px;
    overflow-x: scroll;
    position: relative;

}

.p70 {
    padding-bottom: 70px;

}

.certificatesBox {
    border: 1px solid var(--very-light-gray);
    width: 100%;
    padding: 20px;
    display: flex;
}

.certificatesBoxCol::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.certificatesBoxCol::-webkit-scrollbar-thumb {
    background: rgb(173, 172, 172);
    border-radius: 10px;
}

.certificatesBoxCol::-webkit-scrollbar-thumb:hover {
    background: grey;
}

/* When we have no pdf */
.uploadPdfIconContainer {
    width: 80px;
    height: auto;
}

.uploadPdfIconContainer img {
    width: 100%;
    height: 100%;
}

.pdf_btn label {
    background-color: var(--main-color-red);
    border-radius: 10px;
    padding: 10px;
    color: var(--white-color);
    font-weight: 600;
    font-size: 16px;
}

.btn_main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.uploadMoreBtnBox {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

}

.uploadBtn {
    background-color: var(--main-color-red);
    font-size: 15px;
    padding: 7px 20px;
}

.inp_div {
    margin-top: 20px !important;
}

.btnBox {
    display: flex;
    justify-content: flex-end;
}

.savebtn {
    background-color: var(--main-color-red) !important;
    border-color: var(--main-color-red) !important;
    color: white !important;
    margin-block: 20px;
    padding-block: 10px !important;
    font-size: 16px !important;
    width: 15%;
}


.closeIcon {
    cursor: pointer;
    font-size: 20px;

}




@media screen and (max-width:768px) {

    .userImagesContainer {
        height: 300px;
    }

    .doc_para_div {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .certificatesRow h2 {
        font-size: 35px;
    }
}

@media screen and (max-width:550px) {
    .container {
        max-width: 94%;
    }

    .userImagesContainer {
        height: 250px !important;
        margin-bottom: 40px;
    }

    .doc_para_div {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .certificatesRow h2 {
        font-size: 30px;
    }


}

@media screen and (max-width:375px) {

    .userImagesContainer {
        height: 200px !important;
    }
}