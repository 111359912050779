.noDataContainer {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  padding-top: 2%;
  padding-bottom: 5%;
}
.lottieContainer {
}
.lottieContainer > svg {
  height: 290px !important;
}
.noDataContainer h3 {
  color: var(--red-color);
  font-size: 25px;
  font-weight: 700;
  margin-top: 10px;
}
