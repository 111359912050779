.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001;
}

.footerSection {
}

.bodyContainer {
  max-width: 90% !important;
  margin: 10px auto 60px auto !important;
  box-shadow: 0px 0px 16px 6px rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.bodyContainer p {
  margin-bottom: 0px;
}

.userImagesContainer {
  position: relative;
}

.coverImage {
  width: 100% !important;
  height: 100% !important;
  border-radius: unset !important;
}

.coverImageTagClass {
  object-fit: cover !important;
  object-position: center !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.profileImageContainer {
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%);
}

.userDetailContainer {
  padding: 0px 3%;
}

.centerItems {
  text-align: center;
}

.userNameText {
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.SocialLinkContainer {
  margin: 15px 0px 20px 0px;
}

.socialIcon {
  margin-right: 10px;
  cursor: pointer;
}

.personalDetailRow {
  row-gap: 10px;
}

.personalDetailRow span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: gray;
  word-break: break-all;
}

.certificateAndServicesRow {
}

.certificateAndServicesRow h4 {
  margin: 30px 0px;
}

.certificateAndServicesRow ul li {
  list-style-type: none;
  margin-left: 0 !important;
  padding-left: 0 !important;
  margin-bottom: 10px;
}

.certificateAndServicesRow ul {
  padding-left: 0 !important;
}

.btn {
  background-color: var(--main-color-yellow);
  color: black;
  padding: 10px 40px;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.btnBox {
  display: flex;
  justify-content: center;
}

/*  */
.container {
  max-width: 75%;
}
.customBtn {
  background-color: var(--main-color-red) !important;
  border: none !important;
}
.YeelowBtn {
  background-color: var(--main-color-yellow) !important;
  border: none !important;
  color: black !important;
  margin-left: 16px;
}
.btnDiv {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1440px) {
  .container {
    max-width: 85%;
  }

  .bodyContainer {
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 10px;
    max-width: 85%;
  }
}

@media screen and (max-width: 550px) {
  .container {
    max-width: 94%;
  }

  .certificateAndServicesRow h4 {
    font-size: 24px;
    margin: 15px 0px;
  }

  .certificateAndServicesRow h5 {
    font-size: 20px;
  }

  .certificateAndServicesRow ul li {
    margin-bottom: 5px;
    font-size: 15px;
  }

  .personalDetailRow span {
    margin-left: 8px;
    font-size: 15px;
  }
}

@media screen and (max-width: 425px) {
  .certificateAndServicesRow h4 {
    font-size: 22px;
  }

  .certificateAndServicesRow h5 {
    font-size: 18px;
  }
}
