.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  /* box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%); */
  /* border-radius: 10px; */
  padding: 15px 10px;
  border-bottom: 1px solid var(--light-gray);
  margin-bottom: 10px;
}
.btnContainer {
  padding: 12px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}
.btnContainer span {
  padding-left: 5px;
  color: #fff;
  font-weight: 500;
}
.searchBtnContainer {
  background-color: var(--main-color-yellow);
  position: absolute;
  right: 5px;
  padding-block: 10px;
}
.filterBtnContainer {
  background-color: var(--main-color-red);
}
.downloadBtnContainer {
  background-color: var(--light-gray);
  width: 250px;
}
.downloadBtnContainer span {
  color: var(--black);
}
.searchInputContainer {
  /* width: 73%; */
  width: 100%;
}
.inputContainer {
  position: relative;
  width: 80%;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.sortByContainer {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sortByContainer span {
  font-weight: 500;
  margin-right: 5px;
}
.limitDropDownDiv {
  margin-left: 10px;
}
.limitDropDownDiv > div {
  width: 120px;
}
.limitDropDownDiv > div > div {
  padding: 6px;
  border-radius: 10px;
}
