.modalHeader {
  background-color: var(--main-color-red);
  border-radius: inherit !important;
  padding: 24px;
}
.modalHeader h2 {
  text-align: center;
  color: white;
  width: 100%;
}
.modalBody {
  padding: 24px;
}
.submitBtnDiv {
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.submitBtnDiv button {
  background-color: var(--main-color-yellow);
  border: none;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 10px;
}
.submitBtnDiv button:hover,
.submitBtnDiv button:active {
  background-color: var(--main-color-yellow);
  border: none;
  padding: 8px 16px;
  font-size: 18px;
  border-radius: 10px;
}
.submitBtnDiv button:focus {
  box-shadow: none !important;
  background-color: var(--main-color-yellow);
  border: none;
}
