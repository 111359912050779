.mainContainer {
  padding: 20px;
  max-width: 90%;
  margin: auto;
}

.cardRow {
  row-gap: 20px;
  margin-top: 40px !important;
}
.mainContainer h2 {
  color: var(--main-color-red);
}
@media (max-width: 1220px) {
  .mainContainer {
    max-width: 94%;
  }
}
