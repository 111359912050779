.textAreaBox label {
  margin-bottom: 10px;
}
.inputPassContainer {
  display: flex;
  position: relative;
  background: var(--white-color);
  border-radius: 8px;
  border: 1px solid #ced4da;
  padding: 15px 20px;
}
.leftIconBox {
  /* position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: max-content; */
}

.textAreaBox textarea {
  font-size: 18px;
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: var(--white-color);
  width: 100%;
  outline: none;
  border: 0px;
  padding-left: 5px;
  /* border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 15px 20px;
  font-size: 18px;
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: var(--white-color);
  width: 100%;
  outline: none; */
}

.textAreaBox textarea::placeholder {
  color: var(--text-color-gray);
}
