.mainContainer {
  min-height: 100vh;
  height: auto;
  /* height: 100vh; */
  width: 300px;
  background: linear-gradient(180deg, #ff0000, #580000);
  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
  /* overflow-y: scroll; */
}
.itemsContainer {
  height: calc(100vh - 120px);
  padding-top: 40px;
  overflow-y: scroll;
}
.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ffffff;
  height: 120px;
  /* padding-block: 30px; */
}
.logoContainer img {
  width: 120px;
  height: auto;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 10px;
  padding-left: 20px;
  margin-block: 15px;
  cursor: pointer;
  position: relative;
}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-size: 16px;
  color: #ffffff;
  margin-left: 18px;
}
.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
}

.active {
  background: #ffcd7c;
  margin-right: 30px;
  border-radius: 0px 10px 10px 0px;
}

.active span {
  color: #ffffff;
  font-weight: 600;
}

.innerItemContainer {
  padding-block: 10px;
  padding-left: 60px;
  cursor: pointer;
}

.innerItemContainer span {
  font-size: 14px;
  color: #ffffff;
}

.active svg {
  color: #ffffff !important;
}
.subActive span {
  font-weight: 600;
  text-decoration: underline;
}
.subActive svg {
  color: #ffffff !important;
}

::-webkit-scrollbar {
  display: none;
}
