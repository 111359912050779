.Container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.labelText {}

.inputBox {
  height: 100%;
  /* padding: 20px 20px 20px 30px; */
  padding: 15px 20px;
  width: 100%;
}

.noBorder {
  border: none;
}

.inputPassContainer {
  display: flex;
  position: relative;
  background: var(--white-color);
  border-radius: 8px;
  border: 1px solid #ced4da;
}

.inputPassContainer input {
  font-size: 14px;
  letter-spacing: 1.4px;
  border-radius: inherit;
  background-color: inherit;
  font-size: 16px;
  color: var(--text-color-black);
  outline: none;
  border: none;
}

.inputPassContainer input::placeholder {
  color: var(--text-color-gray);
}

.inputPassContainer .passwordIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #cacaca;
  width: 35px;
  height: auto;
}

.errorText {
  color: red;
}

.disabled {}

.leftIconBox {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: max-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}